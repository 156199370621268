<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="p-2 sm:p-6">
    <div class="flex flex-col">
      <div class="-my-2 sm:-mx-6 lg:-mx-8 overflow-hidden">
        <div
          class="py-2 w-full sm:px-6 lg:px-8"
          v-for="(cat, i) in categories"
          :key="i"
        >
          <div
            class="
              px-6
              py-3
              text-left text-xs
              font-bold
              text-white
              uppercase
              tracking-wider
              bg-secondary
              w-full
              rounded-t-lg
            "
          >
            {{ $t(cat.name) }}
          </div>
          <table
            class="
              divide-y divide-gray-200
              w-440
              xs:min-w-full xs:w-full
              table-fixed
            "
          >
            <thead class="bg-fuchsia-50">
              <tr>
                <th
                  scope="col"
                  colspan="2"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{ $t("name") }}
                </th>

                <th
                  colspan="1"
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{ $t("active") }}
                </th>
                <th scope="col" class="relative px-6 py-3" colspan="1">
                  <span class="sr-only">{{ $t("Edit") }}</span>
                </th>
              </tr>
            </thead>
          </table>
          <div
            class="
              shadow
              border-b border-gray-200
              overflow-x-auto
              xs:overflow-x-hidden
            "
            v-for="(child, i) in cat.children"
            :key="i"
          >
            <table
              class="divide-gray-200 w-440 xs:min-w-full xs:w-full table-fixed"
            >
              <tbody class="">
                <tr class="bg-fuchsia-200">
                  <td
                    colspan="3"
                    class="px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ $t(child.name) }}
                  </td>
                  <td
                    colspan="1"
                    class="px-6 py-4 text-sm font-medium text-white text-right"
                  >
                    <button
                      type="button"
                      @click="openModal(child.id, {}, 'new')"
                      class="
                        inline-flex
                        items-center
                        px-2.5
                        py-1.5
                        border border-transparent
                        text-xs
                        font-medium
                        rounded
                        shadow-sm
                        text-white
                        bg-fuchsia-600
                        hover:bg-fuchsia-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-fuchsia-500
                      "
                    >
                      {{ $t("new") }}
                    </button>
                  </td>
                </tr>
                <tr
                  v-for="(item, i) in child.children"
                  :key="i"
                  :class="i % 2 === 0 ? 'bg-white' : 'bg-fuchsia-50'"
                >
                  <td
                    colspan="2"
                    class="px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ $t(item.name) }}
                  </td>

                  <td colspan="1" class="px-6 py-4 text-sm text-gray-500">
                    <Switch
                      v-model="item.active"
                      @click="setActive(item.active, item.id)"
                      class="
                        flex-shrink-0
                        group
                        relative
                        rounded-full
                        inline-flex
                        items-center
                        justify-center
                        h-5
                        w-10
                        cursor-pointer
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-fuchsia-500
                      "
                    >
                      <span class="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        :class="i % 2 === 0 ? 'bg-white' : 'bg-fuchsia-50'"
                        class="
                          pointer-events-none
                          absolute
                          w-full
                          h-full
                          rounded-md
                        "
                      />
                      <span
                        aria-hidden="true"
                        :class="[
                          item.active ? 'bg-fuchsia-600' : 'bg-gray-200',
                          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200',
                        ]"
                      />
                      <span
                        aria-hidden="true"
                        :class="[
                          item.active ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200',
                        ]"
                      />
                    </Switch>
                  </td>
                  <td
                    colspan="1"
                    class="px-6 py-4 text-right text-sm font-medium"
                  >
                    <div class="flex justify-end space-x-2" v-if="item.user_id">
                      <button @click="openModal(child.id, item, 'update')">
                        <PencilAltIcon class="w-4 text-fuchsia-600" />
                      </button>
                      <button
                        :disabled="!item.can_delete"
                        @click="deleteCategory(item.id)"
                      >
                        <TrashIcon
                          class="w-4"
                          :class="
                            item.can_delete
                              ? 'text-red-400'
                              : 'text-red-200 cursor-not-allowed'
                          "
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal
      :open="showModal"
      @close="showModal = false"
      :type="type"
      :parentId="parentId"
      :data="category"
      :mode="mode"
    ></modal>
  </div>
</template>

<script>
import { PencilAltIcon, TrashIcon } from "@heroicons/vue/outline";
import { Switch } from "@headlessui/vue";
import Modal from "../components/Modal.vue";

export default {
  data() {
    return {
      enabled: false,
      showModal: false,
      type: null,
      parentId: null,
      mode: null,
      category: null,
    };
  },
  computed: {
    categories() {
      return this.$store.state.User.categories;
    },
  },
  components: {
    PencilAltIcon,
    TrashIcon,
    Switch,
    Modal,
  },
  methods: {
    setActive(active, id) {
      this.$store.dispatch("User/setActiveCategory", {
        id,
        active: active ? "active" : "inactive",
      });
    },
    openModal(id, category, mode) {
      this.showModal = true;
      this.parentId = id;
      this.category = category;
      this.mode = mode;
      this.type = "CategoryModal";
    },
    deleteCategory(id) {
      this.$store.dispatch("User/DeleteCategory", id);
    },
  },
  mounted() {
    this.$store.dispatch("User/GetCategory");
  },
};
</script>
<style scoped>
@media (max-width: 640px) {
  /* #category table{
  overflow: hidden;
}
#category tbody{
  width: 100%;
  overflow-x: auto;
}

#category tbody tr{
  min-width: 640px;
  width:640px;
} */
}
</style>
